import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material.module';
import { FacilityCardComponent } from './components/facility-card/facility-card.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TableComponent } from './components/table/table.component';
import { ChipPipe } from './pipes/chip.pipe';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { SelectComponent } from './components/form-fields/select/select.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { InputComponent } from './components/form-fields/input/input.component';
import { RangePickerComponent } from './components/form-fields/range-picker/range-picker.component';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { ChipComponent } from './components/chip/chip.component';
import { InlineFacilityCardComponent } from './components/inline-facility-card/inline-facility-card.component';
import { DatePickerComponent } from './components/form-fields/date-picker/date-picker.component';
import { OverflowTooltipDirective } from './directives/overflow-tooltip.directive';
import { TypeofPipe } from './pipes/typeof.pipe';
import { ResetPasswordRequestDialogComponent } from './components/reset-password-request-dialog/reset-password-request-dialog.component';
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [
    FacilityCardComponent,
    PaginatorComponent,
    TableComponent,
    ChipPipe,
    MomentDatePipe,
    SelectComponent,
    BackButtonComponent,
    InputComponent,
    RangePickerComponent,
    SessionTimeoutDialogComponent,
    ChipComponent,
    InlineFacilityCardComponent,
    DatePickerComponent,
    OverflowTooltipDirective,
    TypeofPipe,
    ResetPasswordRequestDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTableExporterModule.forRoot({xlsxLightWeight: true}),
  ],
  exports: [
    ReactiveFormsModule,
    MaterialModule,
    FacilityCardComponent,
    PaginatorComponent,
    TableComponent,
    ChipPipe,
    MomentDatePipe,
    SelectComponent,
    BackButtonComponent,
    InputComponent,
    RangePickerComponent,
    ChipComponent,
    InlineFacilityCardComponent,
    DatePickerComponent,
    TypeofPipe,
  ],
  providers: [MomentDatePipe],
})
export class SharedModule {
  icons = [
    'logo',
    'potion',
    'merlin',
    'aperture_error',
    'briefcase_error',
    'connection_error',
    'disk_error',
    'reagent_error',
    'tips_error',
  ];
  constructor(
    private matIconReg: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    for (const i of this.icons) {
      this.matIconReg.addSvgIcon(
        i,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `./assets/icons/${i}.svg`
        )
      );
    }
  }
}
