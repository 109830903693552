import { SystemFacility } from '../../../models';

export class SettingsSelectFacilityAction {
  static readonly type = '[Settings] Select Facility';
  constructor(public facility: SystemFacility) {}
}

export class SettingsClear {
  static readonly type = '[Settings] Clear';
}
