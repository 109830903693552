import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarItemsData } from './sidebar-items.data';
import { AppService } from '../../core/services/app.service';
import { AuthService } from '../../core/data-access/auth/auth.service';
import { User } from '../../core/data-access/auth/model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LayoutComponent implements OnInit, OnDestroy {
  sidebarItems = SidebarItemsData;
  liveChatWindow?: Window | null;
  currentUser?: User & { initials?: string };

  constructor(
    public app: AppService,
    private auth: AuthService,
    public loadingBar: LoadingBarService
  ) {
    if (this.auth.getAuthSnapShot()?.user) {
      this.currentUser = { ...this.auth.getAuthSnapShot()!.user! };
      this.currentUser.initials = `${this.currentUser.first_name[0] ?? ''}${
        this.currentUser.last_name[0] ?? ''
      }`.toUpperCase();
    }
  }

  ngOnInit(): void {}

  openEmailSupport() {
    window.location.href = 'mailto:support@genalyte.com';
  }

  openLiveChat() {
    if (!this.liveChatWindow || this.liveChatWindow.closed) {
      this.liveChatWindow = window.open(
        'https://www.genalyte.com/service-support/',
        '_blank'
      );
    } else {
      this.liveChatWindow.focus();
    }
  }

  onLogout() {
    this.auth.logout();
  }

  ngOnDestroy(): void {}
}
