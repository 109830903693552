import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthState } from './core/data-access/auth/state/auth.state';
import { NGXSStorageEngine } from './core/engines';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { LayoutComponent } from './features/layout/layout.component';
import { SharedModule } from './shared/shared.module';
import SettingsState from './core/data-access/settings/state/settings.state';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import { AnonymousLayoutComponent } from './features/layout/components/anonymous-layout/anonymous-layout.component';

@NgModule({
  declarations: [AppComponent, LayoutComponent, AnonymousLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgxsSelectSnapshotModule.forRoot(),
    NgxsModule.forRoot([AuthState, SettingsState], {
      developmentMode: !environment.production,
      executionStrategy: NoopNgxsExecutionStrategy,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth', 'settings'],
    }), // use 'key' props to determine states for persisting only
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Genalyte - Operator App' }),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    LoadingBarModule,
    LoadingBarHttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: NGXSStorageEngine,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
