import { StorageEngine } from '@ngxs/storage-plugin';
import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '../data-access/auth/state/auth.state';

@Injectable()
export class NGXSStorageEngine implements StorageEngine {
    @SelectSnapshot(AuthState.rememberMe) rememberMe!: boolean;

    get length(): number {
        return this.storage().length;
    }

    getItem(key: string): string | null {
        const item = sessionStorage.getItem(key)
            ? sessionStorage.getItem(key)
            : localStorage.getItem(key);
        return item;
    }

    setItem(key: string, val: string): void {
        this.storage().setItem(key, val);
    }

    removeItem(key: string): void {
        this.storage().removeItem(key);
    }

    clear(): void {
        this.storage().clear();
    }

    key(val: number): string | null {
        return this.storage().key(val);
    }

    storage(): Storage {
        if (this.rememberMe) {
            return localStorage;
        } else {
            return sessionStorage;
        }
    }
}
