import { SidebarItemModel } from './sidebar-item.model';

export const SidebarItemsData = [
  {
    title: 'Dashboard',
    path: 'facilities/dashboard',
    icon: 'dashboard',
    iconOutlined: true,
  },
  {
    title: 'Accessioning',
    path: 'facilities/accessioning',
    icon: 'potion',
    svgIcon: true,
  },
  {
    title: 'Test Order Worklist',
    path: 'facilities/worklist',
    icon: 'format_list_bulleted',
  },
] as SidebarItemModel[];
