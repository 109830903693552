import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { AuthApiService } from '../backend/auth-api.service';
import { AuthResponseModel, AuthStateModel, RefreshResponseModel } from '../model';
import { LoginAction, LogoutAction, RefreshTokenAction, RefreshTokenWithSystemIdAction } from './auth.action';

import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { DateUtility } from 'src/app/core/utilities';
import { UserInactivityService } from 'src/app/core/services/user-inactivity.service';
import {SettingsService} from '../../settings/settings.service';

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    access_token: null,
    refresh_token: null,
    user: null,
    remember_me: null,
    expires_in: null,
    expires_date: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private api: AuthApiService,
    private router: Router,
    private ngZone: NgZone,
    private userInactivityService: UserInactivityService,
    private settings: SettingsService
  ) {}

  @Selector()
  static authState(state: AuthStateModel): AuthStateModel | undefined {
    return state;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state?.access_token;
  }

  @Selector()
  static rememberMe(state: AuthStateModel): boolean {
    return !!state?.remember_me;
  }

  @Action(LoginAction)
  login(
    ctx: StateContext<AuthStateModel>,
    { payload }: LoginAction
  ): Observable<AuthResponseModel> {
    return this.api.login(payload).pipe(
      tap((result) => {
        ctx.setState({
          access_token: result.access_token,
          refresh_token: result.refresh_token,
          user: result.user,
          remember_me: payload.remember_me,
          expires_in: result.expires_in,
          expires_date: DateUtility.getCurrentTimeUTCInSecond() + result.expires_in
        });
        this.ngZone.run(() => this.router.navigate(['/']));
      })
    );
  }

  @Action(LogoutAction)
  logOut(ctx: StateContext<AuthStateModel>) {
    this.userInactivityService.cancelTimer();
    ctx.setState({
      access_token: null,
      refresh_token: null,
      user: null,
      remember_me: null,
      expires_in: null,
      expires_date: null,
    });
    localStorage.clear();
    sessionStorage.clear();
    this.settings.clear();
    this.ngZone.run(() => this.router.navigate(['/auth/login']));
  }

  @Action(RefreshTokenAction)
  refreshToken(
    ctx: StateContext<AuthStateModel>
  ): Observable<RefreshResponseModel> {
    return this.api.refreshToken().pipe(
      tap((result) => {
        const ex = ctx.getState().expires_in;
        ctx.patchState({
          access_token: result.AccessToken,
          expires_date: DateUtility.getCurrentTimeUTCInSecond() + (ex ?? 0),
        });
      })
    );
  }

  @Action(RefreshTokenWithSystemIdAction)
  refreshTokenWithSystemId(
    ctx: StateContext<AuthStateModel>,
    payload: {systemId: string}
  ): Observable<RefreshResponseModel> {
    return this.api.refreshTokenWithSystemId(payload.systemId).pipe(
      tap((result) => {
        const ex = ctx.getState().expires_in;
        ctx.patchState({
          access_token: result.AccessToken,
          expires_date: DateUtility.getCurrentTimeUTCInSecond() + (ex ?? 0),
        });
      })
    );
  }
}
