import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, interval, merge, Subject } from 'rxjs';
import {
  filter,
  repeatWhen,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SessionTimeoutDialogComponent } from 'src/app/shared/components/session-timeout-dialog/session-timeout-dialog.component';
import { AuthService } from '../data-access/auth/auth.service';
import { DateUtility } from '../utilities/dateTime';

const USER_INACTIVITY_THRESHOLD = 10 * 60; // in seconds, multiply with 60 to convert in minutes

@Injectable({
  providedIn: 'root',
})
export class UserInactivityService {
  events = ['scroll', 'wheel', 'keydown', 'touchend', 'mousemove'];
  restart$: Subject<void> = new Subject();
  cancelOnLogout$: Subject<void> = new Subject();

  eventStreams = this.events.map((ev) => fromEvent(window, ev));
  allEvents$ = merge(...this.eventStreams);
  constructor(private authService: AuthService, private dialog: MatDialog) {}
  restartTimer() {
    this.restart$.next();
  }
  cancelTimer() {
    this.cancelOnLogout$.next();
  }
  detectUserInactivity() {
    this.allEvents$
      .pipe(
        filter(() => this.authService.isUserAuthenticated() === true),
        switchMap(() =>
          interval(1000).pipe(
            take(USER_INACTIVITY_THRESHOLD),
            takeUntil(this.cancelOnLogout$),
            repeatWhen(() => this.restart$)
          )
        ),
        tap((sec) => {
          const expiredIn = this.authService.getAuthSnapShot()
            ?.expires_date as number;
          const remainTime =
            expiredIn - DateUtility.getCurrentTimeUTCInSecond();
          if (remainTime <= 100 && this.authService.isUserAuthenticated()) {
            this.authService.refreshToken();
          }
        }),
        skipWhile((sec) => sec !== USER_INACTIVITY_THRESHOLD - 1),
        take(1),
        repeatWhen(() => this.restart$)
      )
      .subscribe(() => {
        this.dialog
          .open(SessionTimeoutDialogComponent, {
            panelClass: ['w-11/12', 'md:w-7/12', 'xl:w-5/12', '2xl:w-3/12'],
          })
          .beforeClosed()
          .subscribe((action: 'stay' | 'logout') => {
            this.restartTimer();
            if (action === 'logout') {
              this.authService.logout();
            }
          });
      });
  }
}
