import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { take, repeatWhen, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/data-access/auth/auth.service';

const COUNTER_TRESHOLD = 59;
@Component({
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss'],
})
export class SessionTimeoutDialogComponent implements OnInit, OnDestroy {
  counter = 59;
  sub = new Subscription();
  constructor(
    private dialogRef: MatDialogRef<SessionTimeoutDialogComponent>,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.startCounter();
  }

  onStay() {
    this.dialogRef.close('stay');
  }
  onLogOut() {
    this.dialogRef.close('logout');
  }

  startCounter() {
    this.sub = interval(1000)
      .pipe(
        take(COUNTER_TRESHOLD + 1),
        tap((count) => (this.counter = COUNTER_TRESHOLD - count))
      )
      .subscribe((count) => {
        if (count === COUNTER_TRESHOLD) {
          this.authService.logout();
          this.dialogRef.close();
        }
      });
  }
}
