import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { AuthStateModel } from '../../auth/model';
import { SystemFacility } from '../../../models';
import { SettingsClear, SettingsSelectFacilityAction } from './settings.action';
import { Injectable } from '@angular/core';

export interface SettingsStateModel {
  selectedFacility: SystemFacility | null;
}

export const SETTINGS_STATE_TOKEN = new StateToken<SettingsStateModel>(
  'settings'
);

@State({
  name: SETTINGS_STATE_TOKEN,
  defaults: {
    selectedFacility: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export default class SettingsState {
  @Selector([SETTINGS_STATE_TOKEN])
  static settings(state: SettingsStateModel): SettingsStateModel {
    return state;
  }

  @Action(SettingsSelectFacilityAction)
  selectFacility(
    ctx: StateContext<SettingsStateModel>,
    action: SettingsSelectFacilityAction
  ): void {
    ctx.patchState({
      selectedFacility: action.facility,
    });
  }

  @Action(SettingsClear)
  clear(ctx: StateContext<SettingsStateModel>, action: SettingsClear) {
    ctx.setState({
      selectedFacility: null,
    });
  }
}
