import { AuthPayloadModel } from '../model';

export class LoginAction {
  static readonly type = '[Auth] Login Action]';
  constructor(
    public readonly payload: AuthPayloadModel
  ) {}
}

export class LogoutAction {
  static readonly type = '[Auth] Logout Action]';
}

export class RefreshTokenAction {
  static readonly type = '[Auth] Refresh Token Action]';
}

export class RefreshTokenWithSystemIdAction {
  static readonly type = '[Auth] Refresh Token With System Id Action]';
  constructor(
    public readonly systemId: string
  ) {}
}
