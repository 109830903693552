import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SystemFacility } from '../../models';
import {SettingsClear, SettingsSelectFacilityAction} from './state/settings.action';
import {
  SETTINGS_STATE_TOKEN,
  SettingsStateModel,
} from './state/settings.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private store: Store) {}
  get settings(): SettingsStateModel {
    return this.store.selectSnapshot(SETTINGS_STATE_TOKEN);
  }
  get settings$(): Observable<SettingsStateModel> {
    return this.store.select(SETTINGS_STATE_TOKEN);
  }
  setFacility(facility: SystemFacility) {
    return this.store.dispatch(new SettingsSelectFacilityAction(facility));
  }
  clear() {
    return this.store.dispatch(new SettingsClear());
  }
}
