<div class="h-14 w-full bg-primary-dark flex items-center flex-none relative">
  <button
    class="w-14 h-full flex items-center justify-center"
    (click)="sidenav.toggle()"
  >
    <mat-icon style="font-size: 1.75rem" class="text-white w-7 h-7">menu</mat-icon>
  </button>
  <mat-icon svgIcon="logo" class="text-white w-28 h-7"></mat-icon>
  <div class="ml-auto"><!--Spacer--></div>
  <div class="relative">
    <mat-icon
      (click)="helpSelect.open()"
      class="text-blue-50 cursor-pointer mx-4 align-middle"
    >
      help_outline
    </mat-icon>
    <div class="absolute top-13">
      <mat-select class="w-0 h-0 hide-select-arrow" #helpSelect>
        <mat-option (click)="openLiveChat()" class="help-select-option">
          Support
          <span class="text-gray-disabled text-xs">-Urgent Issues only</span>
          <mat-icon
            class="text-gray-disabled w-5 h-5 text-xl ml-2 leading-none"
          >
            open_in_new
          </mat-icon>
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="profile cursor-pointer" [matMenuTriggerFor]="ProfileMenu">
    {{ currentUser?.initials }}
  </div>
  <mat-menu class="profile-menu mt-1" #ProfileMenu>
    <div class="profile select-none">{{ currentUser?.initials }}</div>
    <div class="mx-4 mt-4 mb-7">
      <p class="opacity-60 text-black font-medium">{{(currentUser?.first_name + ' ' + currentUser?.last_name) | titlecase}}</p>
      <p class="text-xs font-medium text-black my-1">{{currentUser?.role | titlecase}}</p>
      <p class="text-sm text-gray-inactive">{{currentUser?.name}}</p>
    </div>
    <mat-divider></mat-divider>
    <button (click)="onLogout()" mat-menu-item>
      <mat-icon class="mr-2">logout</mat-icon>
      <span class="font-medium">Logout</span>
    </button>
  </mat-menu>
  <mat-progress-bar
    [@fade]
    *ngIf="(loadingBar.value$ | async) !== 0"
    class="w-full absolute z-10 -bottom-1"
    [value]="loadingBar.value$ | async"
    color="accent"
  ></mat-progress-bar>
</div>
<mat-drawer-container class="flex-1">
  <mat-drawer
    class="w-76 bg-primary rounded-r-lg rounded-b-lg"
    #sidenav
    mode="over"
    [autoFocus]="false"
  >
    <div class="mt-4">
      <button
        (click)="sidenav.close()"
        [routerLink]="item.path"
        [routerLinkActive]="'sidebar-item-active'"
        mat-button
        *ngFor="let item of sidebarItems"
        class="sidebar-item-button p-4 w-full rounded-none"
      >
        <mat-icon
          [svgIcon]="item.svgIcon ? item.icon : ''"
          [class.mat-icon-outline]="item.iconOutlined"
          class="text-white"
        >
          {{ item.svgIcon ? '' : item.icon }}
        </mat-icon>
        <p class="ml-8 text-sm text-white">{{ item.title | titlecase }}</p>
      </button>
    </div>
    <p class="text-white text-xs text-center mb-8 mt-auto">
      Operator App version {{ app.version }}
    </p>
  </mat-drawer>
  <router-outlet></router-outlet>
  <div
    class="w-full h-16 bg-primary-dark flex items-center justify-center mt-auto flex-none"
  >
    <mat-icon class="mat-icon-outline text-gray-disabled">email</mat-icon>
    <a href="mailto:support@genalyte.com" class="text-secondary ml-3">
      support@genalyte.com
    </a>
    <a href="tel:858-436-3625" class="text-secondary ml-4">858-436-3625</a>
  </div>
</mat-drawer-container>
