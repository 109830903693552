import moment from 'moment';
export class DateUtility {
  static getCurrentTimeUTCInMillisecond() {
    return moment().toDate().getTime();
  }

  static getCurrentTimeUTCInSecond() {
    return moment().unix();
  }

  static convertMsToReadableRemainingTime(ms: number) {
    let h: number;
    let m: number;
    let s: number;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    const d = Math.floor(h / 24);
    h = h % 24;
    return `${h}:${m}:${s}`;
  }
}
