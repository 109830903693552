import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'moment',
})
export class MomentDatePipe implements PipeTransform {
  transform(value: string | Date, format = ''): string {
    const detectedTimezoneName = moment.tz.guess();
    if (value === null || value === '' || value !== value) {
      return '';
    }
    const formatedDateTime = moment(new Date(value))
      .tz(detectedTimezoneName)
      .format(format);
    return formatedDateTime;
  }
}
