import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthPayloadModel, AuthStateModel } from './model';
import { LoginAction, LogoutAction, RefreshTokenAction, RefreshTokenWithSystemIdAction } from './state/auth.action';
import { AuthState } from './state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private store: Store) {}

  getAuthSnapShot() {
    return this.store.selectSnapshot(AuthState.authState);
  }
  getAuthObserver(): Observable<AuthStateModel> {
    return this.store.select((state) => state.auth);
  }

  isUserAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  login(authPayload: AuthPayloadModel): void {
    this.store.dispatch(new LoginAction(authPayload));
  }
  logout(): void {
    this.store.dispatch(new LogoutAction());
  }
  refreshToken(): void {
    this.store.dispatch(new RefreshTokenAction());
  }
  refreshTokenWithSystemId(systemId: string) {
    return this.store.dispatch(new RefreshTokenWithSystemIdAction(systemId));
  }
}
