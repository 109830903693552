import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../../settings/settings.service';
import {
  AuthPayloadModel,
  AuthResponseModel,
  RefreshResponseModel,
} from '../model';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private httpClient: HttpClient) {}

  login(payload: AuthPayloadModel): Observable<AuthResponseModel> {
    return this.httpClient.post<AuthResponseModel>('/oauth/token', payload);
  }

  sendResetPasswordLink(email: string) {
    return this.httpClient.put(
      `/core/users/password?username=${email}&url=${environment.appUrl + '/auth/reset-password'}`,
      {}
    );
  }
  resetPassword(token: string, newPassword: string) {
    return this.httpClient.put(
      `/core/users/reset_password?token=${token}&password=${newPassword}`,
      {}
    );
  }

  refreshToken(): Observable<RefreshResponseModel> {
    return this.httpClient.post<RefreshResponseModel>(`/auth/refresh`, '');
  }

  refreshTokenWithSystemId(systemId: string): Observable<RefreshResponseModel> {
    return this.httpClient.post<RefreshResponseModel>(`/auth/refresh`, {
      system_id: systemId,
    });
  }
}
