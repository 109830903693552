import { Component, OnInit } from '@angular/core';
import { SettingsService } from './core/data-access/settings/settings.service';
import { UserInactivityService } from './core/services/user-inactivity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'op';
  constructor(
    private userInActivityService: UserInactivityService,
    private settingsService: SettingsService
  ) {}
  ngOnInit() {
    this.userInActivityService.detectUserInactivity();
    window.addEventListener('beforeunload', (event) => {
      this.settingsService.clear();
      return event;
    });
  }
}
